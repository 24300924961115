@import "../node_modules/normalize.css/normalize.css";
@import "./variables.scss";

html{
}

body {
    #root {
        > .main{
            padding-top: #{$headerHeight + $headerMargin};
        }

        .fab{
            position: fixed;
            bottom: 2%;
            right: 1em;
            z-index: 2000;
        }
    }



    h1, .MuiTypography-h1{
      font-size: 3em;
    }

    h2, .MuiTypography-h2{
      font-size: 2.4em;
    }
    
    h3, .MuiTypography-h3{
      font-size: 2em;
    }
    @media screen and (max-width: $bp_mobile) {
      
      h1, .MuiTypography-h1{
        font-size: 2em;
      }
  
      h2, .MuiTypography-h2{
        font-size: 1.8em;
      }
      
      h3, .MuiTypography-h3{
        font-size: 1.6em;
      }
    }

    table{
      border-collapse: collapse;
      &.striped {
        tbody{
          border: 1px solid rgba(0,0,0,0.3);
        }
        tr{
          td{
            padding: 0.4em 0.4em;
          }
        }        
        tr:nth-child(2n){
          background-color: rgba(0,0,0,0.3);
        }
      }
      &.click-list{
        tr{
          cursor: pointer;
          &:hover{
            position: relative;
            &::after{
              position: absolute;
              width: 100%;
              height: 100%;
              display: flex;
              background-color: rbga(200, 250, 200, 0.2);
            }
          }
        }
      }
    }

    
  $positions: relative, absolute;
    @each $var in $positions {
      .position-#{$var}{
        position: #{$var};
      }
      .position-#{$var}-important{
        position: #{$var} !important;
      }
    }

    $colors: white,  red, green, yellow, orange, blue, cyan, teal, gray, black;
  @each $var in $colors {
    .bg-#{$var}{
      background-color: $var;
      &:hover{
        background-color: darken($var, 5% );        
      }
    }

    .bg-#{$var}-important{
      background-color: $var!important;
      &:hover{
        background-color: darken($var, 5% )!important;        
      }
    }
    .color-#{$var}{
      color: $var;
    }
    .color-#{$var}-important{
      color: $var!important;
    }
  }

  .back-link{
    position: absolute;
    right: 0;
    top: 0;
  }
}



// Margins in em

$sides: top, right, bottom, left;


@for $offset from 0 to 21  {      
  .m-#{$offset}{
    margin: #{$offset * 0.1 }em;
  }
  
  .p-#{$offset}{
    padding: #{$offset * 0.1 }em;
  }
  
  @each $var in $sides {
    .m-#{$var}-#{$offset}{
      margin-#{$var}: #{$offset * 0.1 }em;
    }  

    .p-#{$var}-#{$offset}{
      padding-#{$var}: #{$offset * 0.1 }em;
    }  

  }    

  @each $var in $sides {
    .m-#{$var}-#{$offset}-forced{
      margin-#{$var}: #{$offset * 0.1 }em!important;
    }  

    .p-#{$var}-#{$offset}-forced{
      padding-#{$var}: #{$offset * 0.1 }em!important;
    }  

  }    
}



// Flex

.flex{
  display: flex;

  &.column{
    flex-direction: column;
  }
  &.row{
    flex-direction: row;
  }

  &.wrap{
    flex-wrap: wrap;
  }
  &.no-wrap{
    flex-wrap: nowrap;
  }

}

.logo-link{
  color: white;
  text-decoration: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  img, svg{
    max-height: 2em;    
    margin-right: 0.3em;
  }

  @media screen and (max-width: $bp_mobile) {
    span {
      display: none;
    }
  }
}

.grow1{
  flex-grow: 1;
}





.items-center{
  align-items: center;
}
.items-end{
  align-items: flex-end;
}
.items-start{
  align-items: flex-start;
}
.items-stretch{
  align-items: stretch;
}

.content-start{
  justify-content: flex-start;
}
.content-center{
  justify-content: center;
}
.content-end{
  justify-content: flex-end;
}
.content-stretch{
  justify-content: stretch;
}

.content-space-between{
  justify-content: space-between;
}

