@import "../../variables.scss";
.main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .dashboard{
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        height: calc(100vh - 6em);
        overflow: hidden;        
        >.menu-block-wrapper {
            padding: 0em 0 1em;
            overflow-y: auto;
            overflow-x: hidden;
            max-height: calc(100vh - 4em);

            &.has-search{
                padding-top: 4em;
            }

            > .search-block{
                position: fixed;
                top: #{$headerHeight + $headerMargin};
                left: 1em;
                width: calc(100% - 2em);
                background: white;
                // box-shadow: 0 2px 3px rgba(0,0,0,.5);
                z-index: 100;
            }

            > ul.menu-block{
                display: flex;
                flex-wrap: wrap;
                padding: 0;
                margin: -1em;
        
                & > li{
                    display: flex;
                    flex-grow: 1;	
                    // min-height: 5em;
                    flex-direction: column;
                    padding: 1em;
                    background-color: $primaryColor;
                    margin: 1em;
                    border-radius: 0.5em;
                    color: white;
                    a{
                        // color: white;
                        color: rgba(255,255,255,0.6);
                        text-decoration: none;
                        flex-grow: 1;
                    }
                    & > a {
                        // color: #64c8ff;
                        color: rgba(255,255,255,0.6);
                        font-size: 2.4em;
                        font-weight: 300;
                    }
    
                    & > ul {
                        
                    }
                    
                }
                ul {
                    padding: 0;
                    list-style: none;        
                    flex-grow: 1;
                        > li{                        
                            display: flex;
                            flex-direction: column;
                            margin: 0;
                            padding-left: 0.5em;
                            a{                            
                                padding: 1em;
                                color: white;
                                text-decoration: none;
                                flex-grow: 1;
    
                                &:hover{
                                    background-color: rgba(255,255,255,0.1);
                                }
                            }
                        }        
                }
            }
        }
    }
}