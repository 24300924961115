.wrapper-tabs{
    text-align: center;
    display: flex!important;
    align-items: center;
    flex-direction: column;
    h1{
        font-size: 3em;
        margin-top: 1em;
        margin-bottom: 0.3em;
    }
}
#login,#register{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-grow: 1;    

    .login-form{
        flex-grow: 0;
        text-align: center;
        padding: 2em;  
    }
}
