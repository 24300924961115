body{
    .content-close-button{
        position: fixed;
        top:0;
        right:0;
    }
    
    #root{

        .content-button{
            position: fixed;
            z-index: 100;
            left: 1em;
            bottom: 3em;
        }
        
        

        .question-item{
            li{
                &.selected {
                    background: rgb(2, 105, 215);
                    color: white;
                }
        
                &.not-selected {
                    background: rgba(0,0,0,.2);
                    color: rgba(0,0,0,.5);
                }
            }
        
        }
        
        .question-list {
            margin-bottom: 1em;
            .dashboard{
                height: calc(100vh - 9em);
                > .menu-block-wrapper {
                    > ul.menu-block{
                            margin: -0.6em;
                    
                            li.question-dashboard-item{
                                background: teal;
                                font-size: 0.6em;
                                border-radius: 0.5em;    
                                
                                &.answered {
                                    background: #369f6a;
                                }
                            }
                        }
                    
                }

            }
        }
    }
}