@import "../../../variables.scss";

$formPadding: 1em;
.modal-window{
  background-color: rgb(255, 255, 255);
  border-radius: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(40em - #{$formPadding*2});
  max-width: calc(95% - #{$formPadding*2});
  max-height: calc(95vh - #{$formPadding*2});
  display: flex;
  flex-direction: column;
  padding: 1em;

  &.full-screen{
    width: calc(100% - #{$formPadding*2});
    height: calc(100% - #{$formPadding*2});
    max-width: calc(100% - #{$formPadding*2});
    max-height: calc(100% - #{$formPadding*2});
  }

  &.shrink{
    width: auto;
    height: auto;
  }

  @media screen and (max-width: 320px) {
    height: 95vh;
  }

  .modal-header{
    // font-weight: bold !important;
    font-size: 1.4em;
    margin-bottom: 7px;
    display: flex;
    justify-content: center;
    color: $primaryColor;
  }
  .modal-content{
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    align-items: center;
    display: flex;
    flex-grow: 1;
    // margin: 1em;
  }

  &.stretch{
    .modal-content{
      align-items: stretch;
    }
  }

  .modal-buttons-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    button{
      margin: 0.2em 0.6em;
    }
  }
}