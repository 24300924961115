.notification-container{
    
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    overflow: hidden;
    padding: 2em 1em 1em;

    .clear-button-container{
        position: absolute;
        z-index: 1500;
        top: -3.5em;
        right: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        transform: translateX(50%);
        font-size: 0.8em;

        button{
            padding: 0.2em 1em;
            font-size: 0.7em;
            margin: 0.2em;
        }
        .quantity{
            background: darkblue;
            color: white;
            border-radius: 1em;
            white-space: nowrap;
            padding: 0.1em 0.5em;
        }
    }
    .MuiSnackbar-root {
        position: relative;
        margin-top: 2px;
    }

    
}