// Breakpoints
$bp_mobile: 390px;
$bp_mobileLandscape: 480px;
$bp_tablet: 768px;
$bp_tabletLandscape: 1024px;
$bp_desktop: 1200px;
$bp_desktopLarge: 1500px;
$bp_desktopWide: 1920px;

$headerHeight: 2.4em;
$headerMargin: 1em;

$primaryColor: rgb(0, 68, 128);